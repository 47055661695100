import React from "react"
import { useRecoilValue } from "recoil"

import Footer from "../components/footer/footer"
import Header from "../components/header/index"

// Recoil
import { authAtom } from "../atom/auth"

let navBarData = [
  {
    title: "Home",
    url: "/"
  },
  { title: "Legal", url: "/legal/term-of-service" },
  {
    title: "Blog",
    url: "https://blog.dicomwallet.com",
    target: "_blank"
  },
  {
    title: "Contact us",
    url: "/contact-us"
  },
  {
    title: "About us",
    url: "/about-us"
  },
  {
    title: "Pricing",
    url: "/pricing"
  },
  {
    title: "Grants",
    url: "/grants"
  }
]

const Layout = ({ isAuthPage, isLoginPage, isRegisterPage, children }) => {
  const user = useRecoilValue(authAtom)

  return (
    <>
      <div className='text-dicom-on-surface-100'>
        <header>
          <Header
            user={user}
            isAuthPage={isAuthPage}
            isLoginPage={isLoginPage}
            isRegisterPage={isRegisterPage}
            data={navBarData}
          />
        </header>
        <main
          className={`${
            isAuthPage ? "" : "my-16 py-6"
          } space-y-16 lg:container !max-w-7xl !mx-auto px-4 lg:px-8 xl:px-0`}>
          {children}
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  )
}

export default Layout
