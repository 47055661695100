import { Route, Routes, Navigate } from "react-router-dom"
import { useNavigate, useLocation } from "react-router-dom"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useEffect } from "react"
import Home from "./pages/index"
import AboutUs from "./pages/about-us"
import ContactUs from "./pages/contact-us"
import JoinUs from "./pages/auth/join-us"
import Login from "./pages/auth/login"
import Emailconfirmation from "./pages/auth/Emailconfirmation"
import NotFound from "./pages/NotFound"
// analytics
import { initializeAnalytics } from "./utils/analytics"

// dashboard
import AdminNotFound from "./pages/dashboard/NotFound"
import StudyList from "./pages/dashboard/all-studies/StudyList"
import StudyPage from "./pages/dashboard/all-studies/StudyPage"
import ChangePassword from "./pages/dashboard/ChangePassword"
import Profile from "./pages/dashboard/profile"
import Folders from "./pages/dashboard/folders"
import Users from "./pages/dashboard/users"
import Reports from "./pages/dashboard/reports"

// Recoil
import { authAtom, authLoadingAtom } from "./atom/auth"
import ProtectedRoute from "./utils/ProtectedRoute"
import ForgetPassword from "./pages/auth/forget-password"
import ResetPassword from "./pages/auth/reset-password"
import TermOfService from "./pages/LegalandComplance/TermOfService"
import LegalandComplance from "./pages/LegalandComplance/LegalandComplance"
import PrivacyPolicy from "./pages/LegalandComplance/PrivacyPolicy"
import CookiePolicy from "./pages/LegalandComplance/CookiePolicy"
import AcceptableUse from "./pages/LegalandComplance/AcceptableUse"
import OpenSource from "./pages/LegalandComplance/OpenSource"
import RecommendationForUserCompliance from "./pages/LegalandComplance/RecommendationForUserCompliance"
import SocialLoginSuccess from "./components/social/success"
import DCMViewerPage from "./pages/dashboard/viewer/DCMViewerPage"
import { GetLocalStorage, RemoveLocalStorage } from "./services/LocalStorage"
import React from "react"
import { fetchData } from "./services/fetching"
import Pricing from "./pages/pricing/index"
import Stripe from "./components/stripe"
import Payments from "./pages/dashboard/Payments"
import Billings from "./pages/dashboard/users/billings"
import PaymentSuccessful from "./pages/dashboard/Payments/paymentSuccessful"
import Grant from "./pages/grant"
import { logEvent } from "firebase/analytics"
import firebaseAnalytics from "./utils/firebaseAnalytics"
import SetupMfa from "./pages/MFA/SetupMfa"
import ManageSession from "./pages/dashboard/manageSession"
function App() {
  const user = useRecoilValue(authAtom)
  const navigate = useNavigate()
  const location = useLocation()
  const setUser = useSetRecoilState(authAtom)
  const setLoading = useSetRecoilState(authLoadingAtom)

  useEffect(() => {
    const storedUser = GetLocalStorage("login_user")
    if (storedUser) {
      fetchData("GET", `/user/profile`)
        .then((res) => {
          const fetchedUser = res?.data?.data?.user
          if (fetchedUser) {
            setUser(fetchedUser)
          }
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            RemoveLocalStorage("login_key")
            RemoveLocalStorage("login_user")
            setUser(null)
          } else {
            console.log(err)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }, [setLoading, setUser])

  // log the user
  // console.log("user in App.js", user);

  useEffect(() => {
    initializeAnalytics()

    if (window.ga) {
      window.ga("set", "page", location.pathname + location.search)
      window.ga("send", "pageview")
    }
  }, [location, navigate])

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && process.env.REACT_APP_API_KEY) {
      logEvent(firebaseAnalytics, "screen_view", { screen_name: "HomePage" })
    }
  }, [])

  if (useRecoilValue(authLoadingAtom)) {
    return (
      <div className='spinner-container'>
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M4.5 14.25C3.90326 14.25 3.33097 14.0129 2.90901 13.591C2.48705 13.169 2.25 12.5967 2.25 12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75C5.09674 9.75 5.66903 9.98705 6.09099 10.409C6.51295 10.831 6.75 11.4033 6.75 12C6.75 12.5967 6.51295 13.169 6.09099 13.591C5.66903 14.0129 5.09674 14.25 4.5 14.25ZM12 14.25C11.4033 14.25 10.831 14.0129 10.409 13.591C9.98705 13.169 9.75 12.5967 9.75 12C9.75 11.4033 9.98705 10.831 10.409 10.409C10.831 9.98705 11.4033 9.75 12 9.75C12.5967 9.75 13.169 9.98705 13.591 10.409C14.0129 10.831 14.25 11.4033 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM19.5 14.25C18.9033 14.25 18.331 14.0129 17.909 13.591C17.4871 13.169 17.25 12.5967 17.25 12C17.25 11.4033 17.4871 10.831 17.909 10.409C18.331 9.98705 18.9033 9.75 19.5 9.75C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12C21.75 12.5967 21.5129 13.169 21.091 13.591C20.669 14.0129 20.0967 14.25 19.5 14.25Z'
            fill='#1DD3B0'
          />
        </svg>
      </div>
    )
  }

  return (
    <>
      <Routes>
        <Route path={"*"} element={<NotFound />} />
        <Route index path='/' element={<Home />} />
        <Route index path='/stripe' element={<Stripe />} />
        <Route
        // element={
        //   <ProtectedRoute
        //     isAllowed={!user}
        //     // redirectPath={
        //     //   GetLocalStorage("after_Route")
        //     //     ? GetLocalStorage("after_Route")
        //     //     : user?.role === "Admin"
        //     //     ? "/dashboard/reports"
        //     //     : "/dashboard"
        //     // }
        //   />
        // }
        >
          <Route path={"/auth/login"} element={<Login />} />
          <Route path={"/auth/join-us"} element={<JoinUs />} />
        </Route>
        <Route path={"/auth/forget-password"} element={<ForgetPassword />} />
        <Route path={"/auth/reset-password"} element={<ResetPassword />} />
        <Route path={"/about-us"} element={<AboutUs />} />
        <Route path={"/contact-us"} element={<ContactUs />} />
        <Route path={"/pricing"} element={<Pricing />} />
        <Route path={"/grants"} element={<Grant />} />
        <Route path={"/auth/verify"} element={<Emailconfirmation />} />
        <Route path='/dashboard' element={<Navigate to='my-dicom-wallet' replace />} />
        <Route path='/setup-mfa' element={<ProtectedRoute isAllowed={user} redirectPath={"/auth/login"} />}>
          <Route index element={<SetupMfa />} />
        </Route>
        <Route path='/dashboard' element={<ProtectedRoute isAllowed={user} redirectPath={"/auth/login"} />}>
          <Route path={"*"} element={<AdminNotFound />} />
          <Route path={"viewer/:id"} element={<DCMViewerPage />} />

          <Route path={"my-dicom-wallet"}>
            <Route index element={<StudyList />} />
            <Route path={":id"} element={<StudyPage />} />
            <Route path={"get-more-storage/payments"} element={<Payments />} />
            <Route path={"payments/success"} element={<PaymentSuccessful />} />
          </Route>

          <Route path={"shared-by-you"}>
            <Route index element={<StudyList />} />
            <Route path={":id"} element={<StudyPage />} />
          </Route>

          <Route path={"shared"}>
            <Route path={"folder/:sharedFolderId"} element={<StudyList />} />
            <Route path={"study/:id"} element={<StudyPage />} />
          </Route>

          <Route path={"shared-with-you"}>
            <Route index element={<StudyList />} />
            <Route path={":id"} element={<StudyPage />} />
          </Route>

          <Route path={"starred"}>
            <Route index element={<StudyList />} />
            <Route path={":id"} element={<StudyPage />} />
          </Route>

          <Route path={"folders"}>
            <Route index element={<Folders />} />
            <Route path={":folderId"}>
              <Route index element={<StudyList />} />
              <Route path={":id"} element={<StudyPage />} />
            </Route>
          </Route>

          <Route path={"profile"} element={<Profile />} />
          <Route path={"manage-session"} element={<ManageSession />} />
          <Route path={"billing"} element={<Billings />} />
          <Route path={"change-password"} element={<ChangePassword />} />
          <Route
            path={"reports"}
            element={
              <ProtectedRoute isAllowed={user && user?.role === "Admin"} redirectPath={"/dashboard/my-dicom-wallet"}>
                <Reports />
              </ProtectedRoute>
            }
          />

          <Route
            path={"users"}
            element={
              <ProtectedRoute isAllowed={user && user?.role === "Admin"} redirectPath={"/dashboard/my-dicom-wallet"}>
                <Users />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={"/auth/success"} element={<SocialLoginSuccess />} />

        <Route path='/legal' element={<LegalandComplance />}>
          <Route index path={"term-of-service"} element={<TermOfService />} />
          <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"cookie-policy"} element={<CookiePolicy />} />
          <Route path={"acceptable-use"} element={<AcceptableUse />} />
          <Route path={"open-source"} element={<OpenSource />} />
          <Route path={"user-compliance"} element={<RecommendationForUserCompliance />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
