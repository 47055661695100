import React, { useEffect, useState } from "react"
import Layout from "../../containers/Layout"
import { DicomButton } from "../../components/DesignSystem/buttons"
import { useFormik } from "formik"
import * as yup from "yup"
import { GetLocalStorage } from "../../services/LocalStorage"
import { fetchData } from "../../services/fetching"
import toast from "react-hot-toast"
import { Input } from "../../components/DesignSystem/inputs"
import Modal from "../../components/DesignSystem/Modal"
import copy from "copy-to-clipboard"
import UseDocumentTitle from "../../utils/useDocumentTitle"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
export default function SetupMfa() {
  const [status, setStatus] = useState(null)
  const getTwoFAStatus = async () => {
    try {
      const status = await fetchData("get", "/auth/mfa-status")
      setStatus(status.data.data)
      console.log(status.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getTwoFAStatus()
  }, [])
  UseDocumentTitle("DICOM Wallet | Setup MFA", true)
  return (
    <Layout>
      <div className='flex justify-center items-center mt-5'>{status != null && <MfaForm status={status} />}</div>
    </Layout>
  )
}

const MfaForm = ({ status }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState("")
  const [qrImage, setQrImage] = useState("")
  const [secret, setSecret] = useState("")
  const user = GetLocalStorage("login_user")

  const handleAppMFA = async () => {
    try {
      setIsLoading(true)
      const response = await fetchData("POST", "/auth/setup-mfa")
      setSelected("App")
      setQrImage(response.data.data.qrCodeUrl)
      setSecret(response.data.data.secret)
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong.")
    } finally {
      setIsLoading(false)
    }
  }
  const handleEmailMFA = async () => {
    try {
      setIsLoading(true)
      await fetchData("POST", "/auth/send-email-mfa", { userId: user.id })
      setSelected("Email")
    } catch (err) {
      toast.error(err.response?.data?.message || "Something went wrong.")
    } finally {
      setIsLoading(false)
    }
  }
  const handleDisable = () => {
    document.getElementById("warningMfa").checked = true
  }
  const disableMFA = () => {}

  return (
    <div className='rounded-xl shadow bg-white max-w-xl p-7'>
      {selected === "App" && <AppMFA image={qrImage} secret={secret} />}
      {selected === "" && (
        <>
          <h2 className='text-dicom-headline lg:text-dicom-headline2 font-bold'>Two-Factor Authentication</h2>

          {status.twoFAEnabled ? (
            <>
              <p className='text-sm my-4 text-gray-600'>
                {" "}
                You have already chosen an MFA method. Would you like to change it?
              </p>
            </>
          ) : (
            <p className='text-sm my-4 text-gray-600'>
              To enhance your security, DICOMWallet requires you to enable two-factor authentication (2FA). How would
              you like to receive your one-time passwords (OTPs)?
              <br /> You can select multiple options.
            </p>
          )}
          <div>
            <div className='space-y-3 mb-4'>
              <div className='border-2 border-black rounded-xl p-4 flex'>
                <div className='px-1 mx-1'>
                  <h3 className='text-dicom-headline font-semibold'>Mobile phone authentication</h3>
                  <p className='text-sm text-gray-600'>
                    Use a mobile app like Google Authenticator to generate verification codes.
                  </p>
                  {console.log(status.twoFAType)}
                  {status.twoFAEnabled && status.twoFAType.includes("App") ? (
                    <div className='flex gap-2 mt-1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={20}
                        height={20}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#008040'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='inline-block'>
                        <path d='M21.801 10A10 10 0 1 1 17 3.335' />
                        <path d='m9 11 3 3L22 4' />
                      </svg>
                      <p className='text-gray-600 text-sm'>App-based MFA is enabled</p>
                    </div>
                  ) : (
                    <DicomButton
                      onClick={handleAppMFA}
                      disabled={isLoading}
                      type='button'
                      title='Add'
                      btnClass='mt-2 !text-white !bg-dicom-primary-100'
                    />
                  )}
                </div>
              </div>
              <div className='border-2 border-black rounded-xl p-4 flex'>
                <div className='px-1 mx-1'>
                  <h3 className='text-dicom-headline font-semibold'>Email</h3>
                  <p className='text-sm text-gray-600'>Receive verification codes via your registered email.</p>
                  {status.twoFAEnabled && status.twoFAType.includes("Email") ? (
                    <div className='flex gap-2 mt-1'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={20}
                        height={20}
                        viewBox='0 0 24 24'
                        fill='none'
                        stroke='#008040'
                        strokeWidth={2}
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        className='inline-block'>
                        <path d='M21.801 10A10 10 0 1 1 17 3.335' />
                        <path d='m9 11 3 3L22 4' />
                      </svg>
                      <p className='text-gray-600 text-sm'>Email-based MFA is enabled</p>
                    </div>
                  ) : (
                    <DicomButton
                      disabled={isLoading}
                      onClick={handleEmailMFA}
                      type='button'
                      title='Add'
                      btnClass='mt-2 !text-white !bg-dicom-primary-100'
                    />
                  )}
                </div>
              </div>
              {status.twoFAEnabled && (
                <div className='border-2 border-black rounded-xl p-4 flex'>
                  <DisableMFA mfaType={status.twoFAType} user={user} />
                  <div className='px-1 mx-1'>
                    <h3 className='text-dicom-headline font-semibold'>Turn Off Two-Factor Authentication (2FA)</h3>
                    <p className='text-sm text-gray-600'>
                      Disabling 2FA will remove the extra layer of security from your account. You will only need your
                      password to sign in.
                    </p>
                    <DicomButton
                      disabled={isLoading}
                      onClick={handleDisable}
                      type='button'
                      title='Disable'
                      btnClass='mt-2 !text-white !bg-dicom-primary-100'
                    />
                  </div>
                </div>
              )}
            </div>

            <div className='space-y-2'>
              <button
                type='reset'
                onClick={() => window.location.replace("/")}
                className='!capitalize flex text-black border-black hover:bg-white  items-center btn px-6 border bg-white w-full !rounded-dicom-10`}'>
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
      {selected === "Email" && <EmailMFA />}
    </div>
  )
}

const AppMFA = ({ image, secret }) => {
  return (
    <>
      <Modal
        className='!border-gray-400'
        iconClass='!text-gray-800'
        title='Are you sure'
        headerClass='!text-gray-800'
        modalId='appMfa'>
        <p>
          This screen will not be available again. Make sure you have scanned the QR code or saved the secret key in
          your authenticator app.
        </p>
        <div className='grid grid-cols-2 gap-2'>
          <DicomButton
            type='button'
            onClick={() => (window.location.href = "/dashboard")}
            title='Continue'
            btnClass='w-full !text-white !bg-dicom-primary-100'
          />
          <button
            type='reset'
            onClick={() => (document.getElementById("appMfa").checked = false)}
            className='!capitalize flex text-black border-black hover:bg-white  items-center btn px-6 border bg-white w-full !rounded-dicom-10`}'>
            Cancel
          </button>
        </div>
      </Modal>
      <h2 className='text-dicom-headline lg:text-dicom-headline2 font-bold'>Setup Authenticator app</h2>
      <p className='text-sm my-4 text-gray-600'>
        Use a authentication app such as Google Authenticator, Microsoft Authenticator, or 1Password to generate a
        verification code when prompted during sign-in.
      </p>{" "}
      <h2 className='text-dicom-headline lg:text-dicom-headline4 font-bold'>Scan the QR code</h2>
      <p className='text-sm  text-gray-600'>
        Use the Authenticator app to scan the QR code. If you're unable to scan it, manually enter the provided code.{" "}
      </p>
      <div className='my-4 space-y-4'>
        <figure className='p-1 border border-gray-300 mx-auto rounded-xl w-fit'>
          <img className='w-28' src={image} alt='QRCode' />
        </figure>
        <div className='flex justify-center items-center'>
          <p className='text-sm   bg-gray-200 p-2 rounded-lg inline-block'>
            {secret}

            <svg
              onClick={() => {
                copy(secret)
                toast.success("Secret key copied")
              }}
              stroke='currentColor'
              fill='currentColor'
              strokeWidth={0}
              viewBox='0 0 512 512'
              className='w-5 inline ml-2 cursor-pointer'
              xmlns='http://www.w3.org/2000/svg'>
              <rect
                width={336}
                height={336}
                x={128}
                y={128}
                fill='none'
                strokeLinejoin='round'
                strokeWidth={32}
                rx={57}
                ry={57}
              />
              <path
                fill='none'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={32}
                d='m383.5 128 .5-24a56.16 56.16 0 0 0-56-56H112a64.19 64.19 0 0 0-64 64v216a56.16 56.16 0 0 0 56 56h24'
              />
            </svg>
          </p>
        </div>

        <button
          type='reset'
          onClick={() => (document.getElementById("appMfa").checked = true)}
          className='!capitalize flex text-black border-black hover:bg-white  items-center btn px-6 border bg-white w-full !rounded-dicom-10`}'>
          {" "}
          Close
        </button>
      </div>
    </>
  )
}

const EmailMFA = () => {
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = {
    code: ""
  }

  const validationSchema = yup.object({
    code: yup.number().required("Please enter the OTP code")
  })

  const onSubmit = async ({ code }) => {
    setIsLoading(true)
    try {
      await fetchData("POST", "/auth/setup-email-mfa", {
        code
      })
      toast.success("MFA setup complete")
      setTimeout(() => {
        window.location.href = "/dashboard"
      }, 200)
    } catch (error) {
      console.error("OTP verification failed:", error)
      toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true
  })

  return (
    <div className='max-w-md mx-auto text-center'>
      <h2 className='text-dicom-headline lg:text-dicom-headline2 font-bold'>Verify Your Email</h2>
      <p className='text-sm my-4 text-gray-600'>
        Enter the 8-digit verification code sent to your email to complete the setup.
      </p>
      <div className='my-6 space-y-4'>
        <Input nameId='code' placeholder='Enter OTP code' formik={formik} />
        <DicomButton
          disabled={!formik.isValid || isLoading}
          isLoading={isLoading}
          type='submit'
          onClick={formik.handleSubmit}
          title='Submit'
          btnClass='w-full !text-white !bg-dicom-primary-100'
        />
      </div>
    </div>
  )
}

const DisableMFA = ({ mfaType, user }) => {
  const [showForm, setShowForm] = useState(false)
  const [mfaTypeIndex, setMfaTypeIndex] = useState(0)
  const navigate = useNavigate()
  const initialValuesMfa = {
    mfa: ""
  }
  const validationSchemaMfa = Yup.object({
    mfa: Yup.string().required("The MFA code is required")
  })
  const submitMfa = async ({ mfa }) => {
    try {
      await fetchData("POST", "/auth/disable-mfa", {
        code: mfa,
        method: mfaType[mfaTypeIndex]
      })
      toast.success("You have disabled MFA")
      setTimeout(() => {
        navigate("/dashboard")
      }, 200)
    } catch (err) {
      toast.error(err.response.data.message)
      console.log(err)
    }
  }
  const formikMfa = useFormik({
    initialValues: initialValuesMfa,
    validationSchema: validationSchemaMfa,
    onSubmit: submitMfa,
    validateOnMount: true
  })
  const sendMfaEmail = () => {
    fetchData("POST", "/auth/send-email-mfa", {
      userId: user.id
    })
  }
  useEffect(() => {
    if (mfaType !== null && mfaType.length > 1 && mfaType.includes("Email") && mfaType[mfaTypeIndex] === "Email") {
      sendMfaEmail()
    }
  }, [mfaTypeIndex])

  return (
    <>
      <Modal
        className='!border-gray-400'
        iconClass='!text-gray-800'
        title='You are about to disable 2FA'
        headerClass='!text-gray-800'
        modalId='warningMfa'>
        {!showForm ? (
          <p className=''>
            Disabling two-factor authentication (2FA) will remove an extra layer of security from your account. This may
            make your account more vulnerable to unauthorized access. Are you sure you want to proceed?
          </p>
        ) : (
          <form
            id='submitMfa'
            onSubmit={(e) => {
              e.preventDefault()
              formikMfa.submitForm(e)
            }}>
            <Input
              nameId={"mfa"}
              placeholder={`Enter code from ${
                mfaType !== null && mfaType[mfaTypeIndex] === "App" ? "Authenticator app" : "Email"
              }`}
              formik={formikMfa}
            />
            {mfaType.length > 1 && (
              <span
                onClick={() => {
                  formikMfa.resetForm()
                  setMfaTypeIndex((prev) => prev ^ 1)
                }}
                className={
                  "text-dicom-caption cursor-pointer font-medium text-dicom-primary-100 p-dicom-10 text-right "
                }>
                Try another MFA method?
              </span>
            )}
          </form>
        )}
        <div className='grid grid-cols-2 gap-2'>
          {!showForm ? (
            <DicomButton
              onClick={() => setShowForm(true)}
              type='button'
              title='Continue'
              btnClass='w-full !text-white !bg-dicom-primary-100'
            />
          ) : (
            <DicomButton
              form='submitMfa'
              type='submit'
              title='submit'
              btnClass='w-full !text-white !bg-dicom-primary-100'
            />
          )}

          <button
            type='reset'
            onClick={() => (document.getElementById("warningMfa").checked = false)}
            className='!capitalize flex text-black border-black hover:bg-white  items-center btn px-6 border bg-white w-full !rounded-dicom-10`}'>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  )
}
