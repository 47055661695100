import React from "react"

import Layout from "../../containers/Layout"
import { BullHornIcon, CalenderIcon, CheckCircle, EmailIcon, LinkIcon } from "../../components/DesignSystem/svgIcons"

const ResearchGrant = () => {
  return (
    <Layout>
      <div className='max-w-4xl mx-auto py-10 px-5 text-gray-700 '>
        <h1 className='text-3xl font-bold text-gray-900 '>
          DicomWallet Research Grant: Empowering Medical Imaging Studies
        </h1>
        <p className='mb-6'>
          To support groundbreaking research that relies on medical imaging management, we are launching the DicomWallet
          Research Grant. This funding opportunity provides £50,000 in DicomWallet credits, awarded to five research
          teams tackling the challenges of multi-site Clinical Studies. The grant empowers researchers to streamline
          data collection and integrate imaging data seamlessly across multiple institutions into a central repository
          for efficient monitoring and analysis. By reducing administrative burdens and ensuring data compliance, this
          grant enables Principal Investigators and research teams to focus on what truly matters—the science.
        </p>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Who Can Apply?</h2>
        <ul className='list-disc list-inside space-y-2'>
          <li>Academic research institutions</li>
          <li>Epidemiology research groups</li>
          <li>Biobanks</li>
          <li>Public health agencies</li>
          <li>Hospital research departments</li>
          <li>Longitudinal cohort studies</li>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Grant Benefits</h2>
        <ul className='list-disc list-inside space-y-2'>
          <li>
            <CheckCircle className='inline w-5 text-green-500' /> Free DicomWallet credits up to £50K (funding for 5
            projects)
          </li>
          <li>
            <CheckCircle className='inline w-5 text-green-500' /> Training and onboarding support to integrate
            DicomWallet into research workflows
          </li>
          <li>
            <CheckCircle className='inline w-5 text-green-500' /> Potential co-publication or white paper development
            with DicomWallet
          </li>
          <li>
            <CheckCircle className='inline w-5 text-green-500' /> Opportunity to explore long-term partnerships for
            research validation
          </li>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Eligibility Criteria</h2>
        <ul className='list-disc list-inside space-y-2'>
          <li>Affiliation with an academic, clinical, or public research organization</li>
          <li>Research involving medical imaging (CT, MRI, X-ray, etc.)</li>
          <li>Ethical approvals in place (if applicable)</li>
          <li>Demonstrated need for DicomWallet in imaging workflows</li>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Application Requirements</h2>
        <h4>Applicants must submit:</h4>
        <ul className='list-decimal list-inside space-y-2'>
          <li>
            Project Summary (max 250 words)
            <ul className='list-disc list-inside pl-5 space-y-1'>
              <li>Research objectives and significance </li>

              <li>Role of medical imaging in the study </li>

              <li>Expected outcomes </li>
            </ul>
          </li>

          <li>
            Project Timeline & Team Details (max 100 words)
            <ul className='list-disc list-inside pl-5 space-y-1'>
              <li>Estimated project duration</li>
              <li>Key team members and their roles</li>
            </ul>
          </li>
          <li>
            {" "}
            How DicomWallet Will Support the Study (max 300 words)
            <ul className='list-disc list-inside pl-5 space-y-1'>
              <li>Specific imaging management challenges </li>
              <li>How DicomWallet features will enhance the research </li>
            </ul>
          </li>
          <li> Imaging Volume & Data Management Needs (max 100 words) </li>
          <ul className='list-disc list-inside pl-5 space-y-1'>
            <li> Estimated number of imaging studies </li>
            <li> Number of collaborating institutions </li>
          </ul>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Evaluation Criteria</h2>
        <ul className='list-disc list-inside space-y-2'>
          <li>Scientific and societal impact</li>
          <li>Feasibility and readiness of the project </li>
          <li>Innovative use of DicomWallet</li>
          <li>Potential for long-term collaboration</li>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>How to Apply</h2>
        <p>
          Applications can be submitted through the{" "}
          <a href='https://forms.office.com/e/T8g2ywkvRD' target='_blank' className='text-blue-600 underline'>
            <LinkIcon className='inline w-5' /> DicomWallet Grant Portal
          </a>
          .
        </p>
        <p className='mt-2'>
          <CalenderIcon className='inline w-5 text-blue-500' /> Deadline for submissions:{" "}
          <strong>April 15, 2025</strong>
        </p>
        <p>
          <BullHornIcon className='inline w-5 text-yellow-500' /> Winners announced on: <strong>May 1, 2025</strong>
        </p>
        <p className='mt-1'>
          The competition will close once we reach the target number of applications.
          <strong> We encourage you to apply early to secure your chance before the opportunity fills up!</strong>
        </p>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Learn More</h2>
        <ul className='list-disc list-inside space-y-2'>
          <li>
            <a
              href='https://blog.dicomwallet.com/2025/02/14/bridging-the-gap-how-studies-requiring-imaging-data-collection-struggle-without-the-right-tools/'
              target='_blank'
              className='text-blue-600 underline'>
              <LinkIcon className='inline w-5' /> Overcoming Obstacles in Medical Imaging Data Collection
            </a>
          </li>
          <li>
            <a
              href='https://blog.dicomwallet.com/2024/11/27/centralized-real-time-imaging-for-clinical-trials-with-dicomwallet/'
              target='_blank'
              className='text-blue-600 underline'>
              <LinkIcon className='inline w-5' /> Centralized, Real-Time Imaging for Clinical Trials and Research
              Studies with DicomWallet
            </a>
          </li>
        </ul>

        <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>Contact Us</h2>
        <p>
          Have any questions? Feel free to reach out at{" "}
          <a href='mailto:info@dicomwallet.com' className='text-blue-600 underline'>
            <EmailIcon className='inline w-5' /> info@dicomwallet.com
          </a>
          .
        </p>
      </div>
    </Layout>
  )
}

export default ResearchGrant
