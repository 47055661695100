import React, { useEffect, useState } from "react"
import { SwitchButton } from "../DesignSystem/switch"
import { formatCurrency } from "../../utils/functions"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"

const BlackDropdown = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black
    }
  })
)

export default function PriceCalculator({ PopUp }) {
  const priceList = [
    {
      name: "1-100",
      price: "2"
    },
    {
      name: "100-999",
      price: "1"
    },
    {
      name: "Over 1000",
      price: "0.5"
    }
  ]

  const options = [
    {
      id: "centralReview",
      name: (
        <span>
          Central Review Account Setup{" "}
          <BlackDropdown
            className='md:ml-2'
            title='Centralized account with custom folders to organize medical images based on your clinical trial needs (e.g., study points, image types) for both the central account and each site.'>
            {" "}
            <InfoIcon />
          </BlackDropdown>
        </span>
      ),
      price: 2000
    },
    {
      id: "customDicom",
      name: (
        <span>
          Custom Dicom Tag Anonymization{" "}
          <BlackDropdown
            className='md:ml-2 '
            title={`Provides tailored anonymization of DICOM tags based on your specific requests, beyond the standard RSNA guidelines included in the package.`}>
            {" "}
            <InfoIcon />
          </BlackDropdown>
        </span>
      ),
      price: 1000
    },
    {
      name: (
        <span>
          Admin Dashboard <span className='font-normal'> /Per Month</span>{" "}
          <BlackDropdown
            className='md:ml-2'
            title={`Centralized dashboard to manage studies, track clinical trial progress, and monitor each research site's activity.`}>
            {" "}
            <InfoIcon />
          </BlackDropdown>
        </span>
      ),
      id: "adminDashboard",
      price: 200
    },
    {
      id: "extraSecurity",
      name: (
        <>
          Extra Security measures (IP Restriction) <span className='font-normal'>/Per user</span>
          <BlackDropdown
            className='md:ml-2'
            title={`Restricts user access to specified IPs; each user can designate up to 10 IPs for restriction.`}>
            {" "}
            <InfoIcon />
          </BlackDropdown>
        </>
      ),
      price: 20
    }
  ]

  const [userCount, setUserCount] = useState(null)
  const [ImagesCount, setImagesCount] = useState(null)
  const [priceOptions, setPriceOptions] = useState({
    adminDashboard: false,
    centralReview: false,
    customDicom: false,
    extraSecurity: false
  })
  const [disable, setDisable] = useState(true)
  const [total, setTotal] = useState(0)
  const [subTotal, setSubTotal] = useState(0)
  const [extra, setExtra] = useState(0)
  useEffect(() => {
    if (userCount != null && parseInt(userCount) && ImagesCount != null && parseInt(ImagesCount)) {
      let tempImagesCount = 0
      let tempUserCount = parseInt(userCount) * 60
      let tempTotal_year1 = 0
      let tempTotal = 0
      if (ImagesCount <= 99) {
        tempImagesCount = ImagesCount * 2
      } else if (ImagesCount > 99 && ImagesCount <= 999) {
        tempImagesCount = (ImagesCount - 100) * 1 + 200
      } else if (ImagesCount >= 1000) {
        tempImagesCount = (ImagesCount - 1000) * 0.5 + 1100
      }
      setSubTotal(tempImagesCount)
      tempImagesCount = tempImagesCount * parseInt(userCount)
      tempTotal = tempImagesCount + tempUserCount
      if (priceOptions.adminDashboard) {
        tempTotal += 200
      }
      if (priceOptions.centralReview) {
        tempTotal_year1 += 2000
      }
      if (priceOptions.customDicom) {
        tempTotal_year1 += 1000
      }
      if (priceOptions.extraSecurity) {
        tempTotal_year1 += 20 * parseInt(userCount)
      }
      setTotal(tempTotal)

      setExtra(tempTotal_year1)
      setDisable(false)
    } else {
      setTotal("...")
      setSubTotal("...")
      setDisable(true)
      setExtra(0)
    }
  }, [ImagesCount, userCount, priceOptions])

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-3'>
        <div className='w-full space-y-2'>
          <span className='block text-sm font-semibold'>
            Number of Users{" "}
            <BlackDropdown
              className='flex-auto ml-1'
              title='Number of research sites in the study × users per site needing access'>
              {" "}
              <InfoIcon />
            </BlackDropdown>
          </span>
          <input
            type='text'
            placeholder='Enter the number of  user'
            className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:text-dicom-text-100 placeholder:text-sm px-4 w-full'
            onChange={(e) => {
              const value = e.target.value
              if (/^\d*$/.test(value)) {
                setUserCount(value)
              } else {
                e.target.value = userCount
              }
            }}
          />
        </div>
        <div className='w-full space-y-2'>
          <span className=' block text-sm font-semibold tracking-tight'>
            Number of Patient imaging data /Per user{" "}
            <BlackDropdown
              className='flex-auto ml-1'
              title='Number  of medical images (e.g., CT, MRI, X-ray) each user can upload'>
              {" "}
              <InfoIcon />
            </BlackDropdown>
          </span>
          <input
            type='text'
            onChange={(e) => {
              const value = e.target.value
              if (/^\d*$/.test(value)) {
                setImagesCount(value)
              } else {
                e.target.value = ImagesCount
              }
            }}
            placeholder='Enter the number of patient image data'
            className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:text-dicom-text-100 placeholder:text-sm px-4 w-full'
          />
        </div>
      </div>
      <div className=' border-b border-dicom-primary-100/5 pb-1'>
        <label className='text-sm font-normal'>Price per Patient Imaging Data:</label>
        {priceList.map((item, key) => {
          return (
            <div key={`priceIndex-${key + 1}`} className='flex justify-between'>
              <span className='text-dicom-text-100 text-sm'>{item.name}</span>
              <span className='text-dicom-text-100 text-sm'>
                {Intl.NumberFormat("us-en", { currency: "gbp", style: "currency", maximumFractionDigits: 1 }).format(
                  item.price
                )}
              </span>
            </div>
          )
        })}
        <div className='flex justify-between'>
          <span className='text-sm'>Total</span>
          <span className='text-sm'>{parseInt(subTotal) ? `${formatCurrency(subTotal, 1)} / month` : subTotal} </span>
        </div>
      </div>
      <div className='space-y-3 my-3' id='priceCalculatorInputs'>
        {options.map((item, key) => {
          return (
            <div className='flex justify-between py-1'>
              <span className='font-semibold text-sm'>{item.name} </span>
              <span>
                <SwitchButton
                  className='!text-sm'
                  key={`optionsList-${key + 1}`}
                  Ltitle={formatCurrency(item.price, 0, 0)}
                  nameId={item.id}
                  checked={priceOptions[item.id]}
                  onChange={(e) => setPriceOptions({ ...priceOptions, [item.id]: e.target.checked })}
                />
              </span>
            </div>
          )
        })}
      </div>

      <div>
        <h3 className='text-3xl font-bold mb-2'>Your quotes</h3>
        <div className='flex gap-2 justify-between'>
          <div className='py-2 px-4 text-sm  items-center md:justify-between flex flex-col md:flex-row bg-dicom-primary-100/5 rounded-xl  w-full'>
            <div className='flex flex-col'>
              <span className='font-semibold md:text-start text-center'>1 Year</span>
              <span className='md:text-xs text-[10px] text-dicom-text-100 text-center'>Billed Yearly</span>
            </div>
            <span className='mt-auto md:my-auto'>{parseInt(total) ? formatCurrency(total * 12, 1) : total}</span>
          </div>
          <div className='py-2 px-4 text-sm justify-end items-center md:justify-between flex flex-col md:flex-row bg-dicom-primary-100/5 rounded-xl  w-full'>
            <div className='flex justify-center flex-col'>
              <span className='font-semibold md:text-start text-center'>
                2 Year <span className='bg-[#1dd3b0] px-1 py-0.5 rounded-full text-[10px]'>10% off</span>
              </span>
              <span className='md:text-xs text-[10px]  text-dicom-text-100 md:text-start text-center'>
                Billed Yearly
              </span>
            </div>
            <span className='my-auto'>
              {parseInt(total) ? formatCurrency(total * 12 - total * 12 * 0.1, 1) : total}
            </span>
          </div>
          <div className='py-2 px-4  text-sm justify-end items-center md:justify-between flex flex-col md:flex-row bg-dicom-primary-100/5 rounded-xl  w-full'>
            <div className='flex flex-col'>
              <span className='font-semibold md:text-start text-center'>
                3 Year <span className='bg-[#1dd3b0]  px-1 py-0.5 rounded-full text-[10px]'>20% off</span>
              </span>
              <span className='md:text-xs text-[10px] md:text-start text-center  text-dicom-text-100'>
                Billed Yearly
              </span>
            </div>
            <span className='my-auto'>
              {parseInt(total) ? formatCurrency(total * 12 - total * 12 * 0.2, 1) : total}
            </span>
          </div>
        </div>
      </div>

      <div className='my-1 flex flex-col md:gap-2 ml-1'>
        <span className='text-xs'>
          The first year includes a one-time payment of <span>{extra !== 0 ? formatCurrency(extra, 1) : "-"}</span>
        </span>
        <span className='text-xs'>All prices are subject to VAT.</span>
      </div>

      <div
        id='sendQuotationDialog'
        className={` bg-dicom-primary-100 md:px-8 px-4 py-2 rounded-xl mt-4 flex flex-col md:flex-row items-center`}>
        <span className='text-white font-semibold text-sm flex-1 w-full'>Unlock the next phase</span>
        <div className='space-x-3 mt-1 flex md:justify-end w-full md:w-fit'>
          <button
            onClick={() => (window.location.href = "/contact-us")}
            className=' px-3 py-2 border disabled:cursor-not-allowed border-white rounded-xl text-white text-sm'>
            Contact Us
          </button>
          <button
            disabled={disable}
            onClick={() =>
              PopUp({
                userCount,
                ImagesCount,
                priceOptions
              })
            }
            className=' px-3 py-2 border disabled:opacity-90 border-white disabled:cursor-not-allowed bg-white rounded-xl text-dicom-primary-100 text-sm'>
            Email me a quote
          </button>
        </div>
      </div>
    </>
  )
}

const InfoIcon = () => {
  return (
    <svg
      className='inline-block'
      xmlns='http://www.w3.org/2000/svg'
      width={15}
      height={16}
      viewBox='0 0 15 16'
      fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 7.77539C15 11.9175 11.6421 15.2754 7.5 15.2754C3.35786 15.2754 0 11.9175 0 7.77539C0 3.63326 3.35786 0.275391 7.5 0.275391C11.6421 0.275391 15 3.63326 15 7.77539ZM7.5 12.0879C7.81066 12.0879 8.0625 11.8361 8.0625 11.5254V7.02539C8.0625 6.71473 7.81066 6.46289 7.5 6.46289C7.18934 6.46289 6.9375 6.71473 6.9375 7.02539V11.5254C6.9375 11.8361 7.18934 12.0879 7.5 12.0879ZM7.5 4.02539C7.91421 4.02539 8.25 4.36118 8.25 4.77539C8.25 5.1896 7.91421 5.52539 7.5 5.52539C7.08579 5.52539 6.75 5.1896 6.75 4.77539C6.75 4.36118 7.08579 4.02539 7.5 4.02539Z'
        fill='#D9DCFF'
      />
    </svg>
  )
}
