import React from "react"
import toast, { Toaster } from "react-hot-toast"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import axios from "axios"
import { RecoilRoot } from "recoil"
import "react-tooltip/dist/react-tooltip.css"
//
import App from "./App"
import "./styles/index.scss"
import { ContextProvider } from "./Context"
import { RemoveLocalStorage, SetLocalStorage } from "./services/LocalStorage"
// use env setting
axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.withCredentials = true
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response?.status === 401) {
      // If refresh request itself fails with 401, logout and redirect to login
      if (originalRequest.url?.includes("/auth/refresh")) {
        console.error("Refresh token request failed => Logging out user...")
        toast.error("Your session has expired. Please log in again")
        RemoveLocalStorage("login_key")
        RemoveLocalStorage("login_user")
        setTimeout(() => {
          window.location.replace("/login")
        }, 50)
        return Promise.reject(new Error("Session expired, please log in again."))
      }

      // Prevent infinite loops: If this request already retried once, do not retry again
      if (originalRequest._retry) {
        console.error("Token refresh already attempted, stopping retry.")
        return Promise.reject(error)
      }

      originalRequest._retry = true // Mark request as retried

      try {
        // Refresh the token
        const newResponse = await refreshTokenRequest()

        // If refresh fails, logout user
        if (!newResponse) {
          throw new Error("Refresh token invalid")
        }

        // Update Zustand session store with new access token
        SetLocalStorage("login_key", newResponse.token)

        // Update the original request with the new token and retry
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${newResponse.token}`
        }

        return axios(originalRequest)
      } catch (refreshError) {
        console.error("Refresh token failed => logging out user...")
        RemoveLocalStorage("login_key")
        RemoveLocalStorage("login_user")
        setTimeout(() => {
          window.location.replace("/auth/login")
        }, 50)
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  }
)

async function refreshTokenRequest() {
  try {
    const response = await axios.post("/auth/refresh")
    return response.data.data
  } catch (error) {
    console.error("Failed to refresh token", error)
    return false
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <>
    <RecoilRoot>
      <BrowserRouter>
        <Toaster
          toastOptions={{
            duration: 8000
          }}
        />
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </RecoilRoot>
  </>
)
