import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import { authAtom } from "../atom/auth"
import { RemoveLocalStorage } from "./LocalStorage"
import { UserStudies } from "../atom/userStudies"
import { fetchData } from "./fetching"

function useUserActions() {
  const setUser = useSetRecoilState(authAtom)
  const navigate = useNavigate()
  const setUserStudy = useSetRecoilState(UserStudies)
  return {
    UserAuth,
    UserLogOut,
    UserAutoLogOut,
    UserVerify
  }

  function UserAuth(url, data) {
    const auth = axios({
      method: "POST",
      url: url,
      data
    })

    return auth
  }

  function UserVerify(url) {
    const verify = axios({
      method: "GET",
      url: url
    })
    return verify
  }

  function UserLogOut() {
    try {
      fetchData("GET", "/auth/logout")
    } catch (err) {
      console.log(err)
    } finally {
      RemoveLocalStorage("login_key")
      RemoveLocalStorage("login_user")
      RemoveLocalStorage("after_Route")
      setUserStudy({ count: 0, folderCount: 0, folders: [], studies: [] })
      setUser(null)
      navigate("/")
    }
  }

  function UserAutoLogOut(timer) {
    setTimeout(() => {
      UserLogOut()
    }, timer)
  }
}

export { useUserActions }
