import React from "react"

export default function FreePlan() {
  const planDetails = [
    {
      name: "Cloud Storage of DICOM files",
      label: "2 GB"
    },
    {
      name: "Number of Patient imaging data",
      label: "UP to 30"
    },
    {
      name: "Maximum Storage Duration",
      label: "3 Months"
    },
    {
      name: "Anonymization",
      label: "standard"
    },
    {
      name: "Chat, Add comment and Attach file to studies",
      label: "Up to  50"
    },
    {
      name: "Downloads studies",
      label: "Up to 30"
    },
    {
      name: "Number of Monthly shares",
      label: "Up to 50"
    },
    {
      name: "Integrated Dicom Viewer",
      label: "Up to 100 studies"
    }
  ]
  return (
    <div className='mt-5' id='pricing-table'>
      {planDetails.map((item, index) => {
        return (
          <div className='grid grid-cols-3 min-h-[64px] box md:font-semibold text-sm'>
            <div className='col-span-2 text-left px-7 my-auto'>{item.name}</div>
            <div className='text-center w-full  my-auto'>{item.label}</div>
          </div>
        )
      })}
    </div>
  )
}

export const FeatureComponent = () => {
  return (
    <>
      <span className='text-dicom-primary-100 font-bold mt-auto'>Features</span>
      <button
        onClick={() => (window.location.href = "/auth/login")}
        className='border -mt-4 border-dicom-primary-100 text-dicom-primary-100 disabled:cursor-not-allowed disabled:opacity-60 disabled:border-[#021BFA] rounded-xl px-5 py-2'>
        Get started
      </button>
    </>
  )
}
