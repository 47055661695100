import React, { useEffect, useRef, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import * as Yup from "yup"
import toast from "react-hot-toast"
import jwt from "jwt-decode"
// Recoil
import { useRecoilState, useSetRecoilState } from "recoil"
import { authAtom } from "../../atom/auth"

// components
import Layout from "../../containers/Layout"
import useDocumentTitle from "../../utils/useDocumentTitle"
import { Input } from "../../components/DesignSystem/inputs"
import { DicomButton } from "../../components/DesignSystem/buttons"
import { useUserActions } from "../../services/AuthActions"
import { GetLocalStorage, RemoveLocalStorage, SetLocalStorage } from "../../services/LocalStorage"
import Socialbtns from "../../components/socialbtns"
import { fetchData } from "../../services/fetching"

const Login = () => {
  useDocumentTitle("Login", true)
  const [mfa, setMfa] = useState(false)
  const [show, setShow] = useState(false)
  const [mfaTypeIndex, setMfaTypeIndex] = useState(0)
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(authAtom)
  const userActions = useUserActions()
  const [isLoading, setisLoading] = useState(false)
  const verifySession = useRef(null)
  const [sessionId, setSessionId] = useState(null)
  const mfaType = useRef(null)
  const initialValues = {
    email: "",
    password: ""
  }
  const initialValuesMfa = {
    mfa: ""
  }
  const validationSchemaMfa = Yup.object({
    mfa: Yup.string().required("The MFA code is required")
  })

  const submitMfa = async () => {
    const user = GetLocalStorage("login_user")
    if (!user.twoFAEnabled) {
      try {
        const res = await fetchData("post", "/auth/validate-session-ip", {
          code: formikMfa.values.mfa.toString(),
          userId: user.id,
          sessionId
        })
        SetLocalStorage("login_key", res?.data?.data?.token)
        SetLocalStorage("login_user", res?.data?.data?.user)
        setUser(res?.data?.data?.user)
        let loginRoute = GetLocalStorage("after_Route")
          ? GetLocalStorage("after_Route")
          : res?.data?.data?.user?.role === "Admin"
          ? "/dashboard/reports"
          : "/dashboard"
        RemoveLocalStorage("after_Route")
        navigate(loginRoute)
      } catch (err) {
        toast.error(err.response.message || "Something went wrong")
      }
      return
    }
    fetchData("post", `${mfaType.current[mfaTypeIndex] === "Email" ? "/auth/verify-email-otp" : "/auth/verify-mfa"}`, {
      token: formikMfa.values.mfa,
      userId: user.id
    })
      .then((res) => {
        SetLocalStorage("login_key", res?.data?.data?.token)
        // SetLocalStorage("login_user", res?.data?.data?.user)
        formik.resetForm()
        // setUser(res?.data?.data?.user)
        let loginRoute = GetLocalStorage("after_Route")
          ? GetLocalStorage("after_Route")
          : res?.data?.data?.user?.role === "Admin"
          ? "/dashboard/reports"
          : "/dashboard"
        RemoveLocalStorage("after_Route")
        navigate(loginRoute)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log(err)
      })
  }

  const formikMfa = useFormik({
    initialValues: initialValuesMfa,
    validationSchema: validationSchemaMfa,
    onSubmit: submitMfa,
    validateOnMount: true
  })

  const validationSchema = Yup.object({
    email: Yup.string().required("The Email Address is required").email("Email is invalid."),
    password: Yup.string().required("The Password is required")
  })

  const onSubmit = () => {
    setisLoading(true)
    userActions
      .UserAuth("/auth/login", formik.values)
      .then(async (res) => {
        if (res?.data?.data && !res.data.data.user.twoFAEnabled) {
          toast.success("You have successfully logged in")
          setisLoading(false)
          SetLocalStorage("login_key", res?.data?.data?.token)
          SetLocalStorage("login_user", res?.data?.data?.user)
          formik.resetForm()
          setUser(res?.data?.data?.user)
          verifySession.current = res.data.data.verifyIp
          setSessionId(res.data.data.sessionId)
          if (res?.data?.data?.user.mfaAlert) {
            setTimeout(() => {
              handleSession()
            }, 50)
          } else {
            setShow(true)
          }
        } else {
          mfaType.current = res.data.data.user.twoFAType
          setUser(res.data.data.user)
          SetLocalStorage("login_user", res?.data?.data?.user)
          if (res.data.data.user.twoFAType[0] === "Email") {
            sendMfaEmail()
          }
          setMfa(true)
        }
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast.error(err?.response?.data?.message)
        }
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const sendMfaEmail = () => {
    const user = GetLocalStorage("login_user")
    fetchData("POST", "/auth/send-email-mfa", {
      userId: user.id
    })
  }
  useEffect(() => {
    if (
      mfaType.current !== null &&
      mfaType.current.length > 1 &&
      mfaType.current.includes("Email") &&
      mfaType.current[mfaTypeIndex] === "Email"
    ) {
      sendMfaEmail()
    }
  }, [mfaTypeIndex])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    validateOnMount: true
  })

  useEffect(() => {
    if (GetLocalStorage("after_Route")) {
      if (GetLocalStorage("toShowed")) {
        let msg = !GetLocalStorage("login_key")
          ? "Login to have access to this feature"
          : "Your session has expired. Please login again"
        toast.error(msg, { duration: 3500 })
        SetLocalStorage("toShowed", false)
      }
    }
  }, [])

  const handleSession = async () => {
    let checkbox = document.getElementById("mfaDisableCheckbox")?.checked || false
    const user = GetLocalStorage("login_user")
    if (verifySession.current) return
    if (checkbox) {
      try {
        await fetchData("POST", "/auth/mfa-alert", { alert: checkbox })
        let loginRoute = GetLocalStorage("after_Route") || (user.role === "Admin" ? "/dashboard/reports" : "/dashboard")
        RemoveLocalStorage("after_Route")
        navigate(loginRoute)
      } catch (error) {
        console.error("Failed to send MFA alert:", error)
      }
    } else {
      let loginRoute = GetLocalStorage("after_Route") || (user.role === "Admin" ? "/dashboard/reports" : "/dashboard")
      RemoveLocalStorage("after_Route")
      navigate(loginRoute)
    }
  }

  return (
    <>
      <Layout isAuthPage={true} isLoginPage={true}>
        <div className='py-16 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-dicom-30'>
          <div className='order-1 col-span-3 md:col-span-3 lg:col-span-5 xl:col-span-9 grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12'>
            <div className='relative space-y-dicom-30 col-span-3 md:col-span-12 lg:col-span-5 xl:col-span-5 pt-dicom-30'>
              <div className='absolute !z-0 -left-44 -top-36'>
                <svg
                  className='!w-[100%]'
                  width='547'
                  height='560'
                  viewBox='0 0 547 560'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <g opacity='0.2' filter='url(#filter0_f_56_1543)'>
                    <circle cx='267' cy='280' r='174' fill='#1DD3B0' />
                  </g>
                  <defs>
                    <filter
                      id='filter0_f_56_1543'
                      x='-13'
                      y='0'
                      width='560'
                      height='560'
                      filterUnits='userSpaceOnUse'
                      colorInterpolationFilters='sRGB'>
                      <feFlood floodOpacity='0' result='BackgroundImageFix' />
                      <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                      <feGaussianBlur stdDeviation='53' result='effect1_foregroundBlur_56_1543' />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div className='relative !z-10 space-y-dicom-30'>
                <h1 className='text-dicom-headline1 md:text-dicom-headline1 lg:text-[40px] xl:text-dicom-headline1 font-bold text-dicom-primary-200'>
                  Sign in to DICOMWallet
                </h1>
                <p className='md:w-4/6 lg:w-4/6 xl:w-3/6'>
                  If you dont have an account you can{" "}
                  <NavLink to={"/auth/join-us"} className={"text-dicom-primary-100 text-dicom-body1 font-medium"}>
                    Register here.
                  </NavLink>
                </p>
              </div>
            </div>
            <div className='col-span-12 md:col-span-12 lg:col-span-7 xl:col-span-7'>
              <img className='w-[450px]' src='/images/auth.png' alt='' />
            </div>
          </div>
          <div className='relative -order-1 md:order-1 col-span-3 md:col-span-3 lg:col-span-3 xl:col-span-3'>
            <div className='space-y-dicom-15'>
              {!mfa ? (
                <>
                  {verifySession.current === true ? (
                    <>
                      <Input nameId={"mfa"} placeholder={`Enter code from email`} formik={formikMfa} />
                      <DicomButton
                        disabled={!formikMfa.isValid ? true : false || isLoading}
                        isLoading={isLoading}
                        type={"submit"}
                        onClick={submitMfa}
                        title={"Submit"}
                        btnClass={"w-full !text-white !bg-dicom-primary-100"}
                      />
                      <p className='text-gray-600 text-sm mt-2'>
                        We detected a login attempt from a new IP address. To ensure this IP can be trusted, please
                        verify it using the code sent to your registered email.
                      </p>
                    </>
                  ) : (
                    <>
                      {show ? (
                        <>
                          <div className='bg-[#eaf0f7] p-4 mt-1'>
                            <p className='text-sm rounded-lg'>
                              To keep your account safe, DicomWallet highly recommends enabling Multi-Factor
                              Authentication (MFA). This extra layer of security helps prevent unauthorized access. Set
                              up MFA now for better protection.
                            </p>
                            <label className='text-sm space-x-2'>
                              <input id='mfaDisableCheckbox' type='checkbox' />
                              <span>Do not show this screen next time</span>
                            </label>
                          </div>
                          <DicomButton
                            onClick={() => (window.location.href = "/setup-mfa")}
                            title={"Set up MFA"}
                            btnClass={"w-full !text-white !bg-dicom-primary-100"}
                          />
                          <button
                            type='reset'
                            onClick={() => handleSession()}
                            className='!capitalize flex text-black border-black hover:bg-white  items-center btn px-6 border bg-white w-full !rounded-dicom-10`}'>
                            Continue
                          </button>
                        </>
                      ) : (
                        <>
                          <Input nameId={"email"} placeholder={"Enter Email address"} formik={formik} />
                          <Input nameId={"password"} placeholder={"Password"} formik={formik} isPassword />
                          <NavLink
                            to={"/auth/forget-password"}
                            className={"text-dicom-caption font-medium text-dicom-primary-100 p-dicom-10"}>
                            Forget your password
                          </NavLink>
                          <DicomButton
                            disabled={!formik.isValid ? true : false || isLoading}
                            isLoading={isLoading}
                            type={"submit"}
                            onClick={onSubmit}
                            title={"Sign in"}
                            btnClass={"w-full !text-white !bg-dicom-primary-100"}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Input
                    nameId={"mfa"}
                    placeholder={`Enter code from ${
                      mfaType.current[mfaTypeIndex] === "App" ? "Authenticator app" : "Email"
                    }`}
                    formik={formikMfa}
                  />
                  {mfaType.current.length > 1 && (
                    <span
                      onClick={() => {
                        formikMfa.resetForm()
                        setMfaTypeIndex((prev) => prev ^ 1)
                      }}
                      className={
                        "text-dicom-caption cursor-pointer font-medium text-dicom-primary-100 p-dicom-10 text-right "
                      }>
                      Try another MFA method?
                    </span>
                  )}

                  <DicomButton
                    disabled={!formikMfa.isValid ? true : false || isLoading}
                    isLoading={isLoading}
                    type={"submit"}
                    onClick={submitMfa}
                    title={"Submit"}
                    btnClass={"w-full !text-white !bg-dicom-primary-100"}
                  />
                  <NavLink
                    to={"/contact-us"}
                    className={"text-dicom-caption font-medium text-dicom-primary-100 p-dicom-10"}>
                    Need help with MFA?
                  </NavLink>
                </>
              )}

              {!show && <Socialbtns title={"Or continue with"} />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Login
