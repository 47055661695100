import React, { useEffect, useState } from "react"
import AdminLayout from "../../containers/AdminLayout"
import { fetchData } from "../../services/fetching"
import { UAParser } from "ua-parser-js"
import { Card } from "@mui/material"
import {
  SiSafari,
  SiGooglechrome,
  SiFirefoxbrowser,
  SiOpera,
  SiBrave,
  SiMacos,
  SiLinux,
  SiAndroid,
  SiIos
} from "react-icons/si"
import { FaMapMarkerAlt, FaWindows } from "react-icons/fa"
import { ImEdge } from "react-icons/im"
import { TbDeviceDesktop, TbDeviceLaptop, TbDeviceMobile, TbWorldWww } from "react-icons/tb"
import moment from "moment"
import { useUserActions } from "../../services/AuthActions"
import toast from "react-hot-toast"
import UseDocumentTitle from "../../utils/useDocumentTitle"

const BrowserIconMap = {
  Safari: <SiSafari className='inline-block text-blue-500 text-4xl' />,
  Chrome: <SiGooglechrome className='inline-block text-[#4285F4] text-4xl' />,
  Edge: <ImEdge className='inline-block text-cyan-600 text-4xl' />,
  Firefox: <SiFirefoxbrowser className='inline-block text-orange-500 text-4xl' />,
  Opera: <SiOpera className='inline-block text-red-500 text-4xl' />,
  Brave: <SiBrave className='inline-block text-orange-600 text-4xl' />,
  Web: <TbWorldWww className='inline-block text-blue-500 text-4xl' />
}
const OSIconMap = {
  Windows: <FaWindows className='inline-block text-[#00adef] text-4xl' />,
  "Mac OS": <SiMacos className='inline-block text-gray-800 text-4xl' />,
  Linux: <SiLinux className='inline-block text-yellow-600 text-4xl' />,
  Android: <SiAndroid className='inline-block text-green-600 text-4xl' />,
  iOS: <SiIos className='inline-block text-sky-600 text-4xl' />,
  Default: <TbWorldWww className='inline-block text-gray-500 text-4xl' />
}
const DeviceIconMap = {
  mobile: <TbDeviceMobile className='inline-block text-[#575757] text-4xl' />,
  tablet: <TbDeviceLaptop className='inline-block text-[#575757] text-4xl' />,
  desktop: <TbDeviceDesktop className='inline-block text-[#575757] text-4xl' />
}
export default function ManageSession() {
  const [sessions, setSessions] = useState([])
  UseDocumentTitle("Dashboard | Manage Session", true)
  const current = UAParser()
  const getSessions = async () => {
    try {
      const res = await fetchData("Get", "/auth/sessions")
      const sessions = res.data.data
      const data = []
      sessions.map((item) => {
        const agent = UAParser(item.deviceInfo)
        data.push({ ...item, deviceInfo: agent })
        console.log(agent.os)
      })
      setSessions(data)
      console.log(data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getSessions()
  }, [])

  return (
    <AdminLayout>
      <section className=' px-8 py-3 space-y-5'>
        <h2 className='text-2xl font-semibold text-gray-700'>Manage your sessions</h2>
        <div className='flex gap-5'>
          {sessions.map((item) => {
            return <SessionIcon setSessions={setSessions} session={item} currentSession={current} />
          })}
        </div>
      </section>
    </AdminLayout>
  )
}

function isCurrentSession(session, currentDeviceInfo) {
  return (
    session.deviceInfo?.os?.name === currentDeviceInfo.os.name &&
    session.deviceInfo?.os?.version === currentDeviceInfo.os.version &&
    session.deviceInfo?.browser?.name === currentDeviceInfo.browser.name
  )
}

const SessionIcon = ({ session, currentSession, setSessions }) => {
  const minutesAgo = moment().diff(moment(session.updatedAt), "minutes")
  const isActive = minutesAgo < 15
  const current = isCurrentSession(session, currentSession)
  const { UserLogOut } = useUserActions()
  const handleSession = async (id) => {
    if (current) {
      UserLogOut()
      return
    }
    try {
      await fetchData("delete", `/auth/session/${id}`)
      toast.success("Session removed")
      setSessions((prev) => prev.filter((item) => item.id !== id))
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card className='py-5 max-w-sm px-4'>
      {current && <p className='font-semibold text-xs'>Current session</p>}
      {isActive && (
        <p className='flex gap-2 text-sm items-center '>
          <span className='w-2.5 inline-block h-2.5 rounded-full bg-green-600'></span>
          Active
        </p>
      )}
      <div className='gap-5 py-5 w-fit grid grid-cols-2 '>
        <div className='flex gap-3'>
          {OSIconMap[session.deviceInfo.os.name] || OSIconMap.Default}
          <div className='flex flex-col'>
            <span className='text-xs font-light'>
              {session.deviceInfo.os.name} {session.deviceInfo.os.version}
            </span>
            <span className='text-xs'>Operating System</span>
          </div>
        </div>
        <div className='flex gap-3'>
          {BrowserIconMap[session.deviceInfo.browser.name] || BrowserIconMap.Web}
          <div className='flex flex-col'>
            <span className='text-xs font-light'>{session.deviceInfo.browser.name}</span>
            <span className='text-xs'>Browser</span>
          </div>
        </div>
        <div className='flex gap-3'>
          <FaMapMarkerAlt className='inline-block text-[#575757] text-4xl' />
          <div className='flex flex-col'>
            <span className='text-xs font-light'>-</span>
            <span className='text-xs'>IP Address</span>
          </div>
        </div>
        <div className='flex gap-3'>
          {DeviceIconMap[session.deviceInfo.device.type] || DeviceIconMap.desktop}
          <div className='flex flex-col'>
            <span className='text-xs font-light'>{session.deviceInfo.device.model || "PC"} </span>
            <span className='text-xs'>Device</span>
          </div>
        </div>
      </div>
      <button
        onClick={() => handleSession(session.id)}
        className='inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 bg-red-500 text-white  shadow-sm hover:bg-destructive/90 h-9 px-4 py-2'>
        Logout
      </button>
    </Card>
  )
}
