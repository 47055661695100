import React, { useEffect, useState } from "react"
import Layout from "../../containers/Layout"
import Skeleton from "../../components/pricing/skeleton"
import FreePlan, { FeatureComponent } from "../../components/pricing/freePlan"
import PriceCalculator from "../../components/pricing/priceCalculator"
import SendQuotePopup from "../../components/pricing/sendQuotePopup"
import axios from "axios"
import toast from "react-hot-toast"

export default function Pricing() {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [priceItems, setPriceItems] = useState(null)
  const handlePriceQuote = async (e) => {
    toast.promise(
      axios.post(`/payments/quotation-request`, {
        fullName: e.fullName,
        email: e.email,
        companyName: e.companyName,
        jobTitle: e.jobTitle,
        userCount: priceItems.userCount,
        ImagesCount: priceItems.ImagesCount,
        priceOptions: priceItems.priceOptions
      }),
      {
        loading: "We are processing your request",
        error: "There was an error sending the quote, please try again",
        success: "Thank you for your interest, we have emailed you the quote."
      }
    )
  }

  const handleOpenPopup = (e) => {
    setPriceItems(e)
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }
  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("overflow-hidden")
    } else {
      document.body.classList.remove("overflow-hidden")
    }

    // Clean up on component unmount
    return () => {
      document.body.classList.remove("overflow-hidden")
    }
  }, [isPopupOpen])

  return (
    <Layout>
      {isPopupOpen && <SendQuotePopup handleSubmit={handlePriceQuote} onClose={handleClosePopup} />}
      <div className='flex flex-col md:flex-row gap-5 my-1 justify-center'>
        <Skeleton
          className='px-4'
          title='Basic'
          innerDivClass='md:pl-[30px]'
          subtitle={"Free For Personal Use "}
          FeatureComponent={<FeatureComponent />}>
          <FreePlan />
        </Skeleton>
        <Skeleton
          featured={true}
          className='w-full px-4 md:px-[30px]'
          title='Enterprise'
          subtitle={"You can configure features and estimate cost for your specific need"}
          FeatureComponent={<span className='text-dicom-primary-100 font-bold mt-auto'>Features</span>}>
          <PriceCalculator PopUp={handleOpenPopup} />
        </Skeleton>
      </div>
    </Layout>
  )
}
